<template>
  <v-dialog v-model="show" max-width="80%">
    <v-card>
      <v-card-title>
        <span>Crear nueva orden de Compra</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="buyOrderForm" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="provider_name"
                  label="Proveedor"
                  readonly
                  maxlength="50"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="buyOrder.buyorder_nro"
                  label="Nro. de orden de compra"
                  counter
                  maxlength="15"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  v-model="buyOrder.currency"
                  :items="currency_items"
                  :rules="[v => !!v || 'Seleccione una moneda']"
                  label="Moneda"
                  required
                >
                </v-select>
              </v-col>
              <!-- <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="buyOrder.ammount_payed"
                  :prefix="buyOrder.currency === 'SOLES' ? 'S/.' : '$'"
                  type="number"
                  min="0"
                  label="Monto pagado"
                  readonly
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="buyOrder.buy_method"
                  label="Método de compra"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="buyOrder.bill_nro"
                  label="Nro. de comprobante"
                  counter
                  maxlength="15"
                  autocomplete="nope"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-menu
                  ref="bill_emission_menu"
                  v-model="bill_emission_menu"
                  :close-on-content-click="false"
                  :return-value.sync="buyOrder.bill_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="buyOrder.bill_date"
                      label="Fecha de emisión de comprobante"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="buyOrder.bill_date"
                    no-title
                    scrollable
                    locale="es-pe"
                    @input="$refs.bill_emission_menu.save(buyOrder.bill_date)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-menu
                  ref="expire_menu"
                  v-model="expire_menu"
                  :close-on-content-click="false"
                  :return-value.sync="buyOrder.arrival_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="buyOrder.arrival_date"
                      label="Fecha de llegada de la compra"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="buyOrder.arrival_date"
                    no-title
                    scrollable
                    locale="es-pe"
                    @input="$refs.expire_menu.save(buyOrder.arrival_date)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="6" md="9">
                <v-autocomplete
                  v-model="selectedProduct"
                  :items="providerProducts"
                  label="Escriba el codigo de un producto"
                  item-text="description"
                  item-value="id"
                  return-object
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.description"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.code"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="2" align-self="center">
                <v-btn elevation="2" color="secondary" @click="addProduct">
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="selectedProviderProducts"
                  item-key="id"
                  class="elevation-3"
                  :items-per-page="ppr"
                  ref="productsTable"
                >
                  <template v-slot:item.lote="{ item }">
                    <v-edit-dialog :return-value.sync="item.lote">
                      {{ item.lote }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="item.lote"
                          :rules="loteRules"
                          label="Lote"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <v-edit-dialog :return-value.sync="item.price">
                      {{ item.price }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="item.price"
                          label="Precio"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <v-edit-dialog :return-value.sync="item.quantity">
                      {{ item.quantity }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="item.quantity"
                          label="Cantidad"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="right" class="text-h6">
                Monto de la compra:
                {{ buyOrder.currency === 'SOLES' ? 'S/.' : '$' }}
                {{ current_ammount }}
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="show = false">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="storeBuyOrder">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    buyOrder: {
      currency: 'SOLES'
    },
    buyOrderRules: {
      name: [v => !!v || 'Ingrese el nombre del proveedor'],
      phone: [
        v => !!v || 'Ingrese el teléfono del proveedor',
        v =>
          (v && v.length <= 9) ||
          'El teléfono debe ser menor o igual a 9 caracteres'
      ],
      ruc: [
        v => !!v || 'Ingrese el ruc del proveedor',
        v => (v && v.length === 11) || 'El RUC debe tener 11 caracteres'
      ]
    },
    valid: true,
    currency_items: ['SOLES', 'DOLARES'],
    emission_menu: false,
    bill_emission_menu: false,
    expire_menu: false,
    provider_name: '',
    selectedProduct: {},
    providerProducts: [],
    selectedProviderProducts: [],
    loteRules: [v => v.length === 8 || 'Lote debe tener 8 cifras'],
    headers: [
      {
        text: 'Código',
        align: 'start',
        sortable: true,
        value: 'code',
        divider: true
      },
      {
        text: 'Descripción',
        value: 'description',
        filtereable: true,
        divider: true
      },
      { text: 'Precio', value: 'price', divider: true },
      {
        text: 'Cantidad',
        value: 'quantity',
        align: 'center',
        divider: true
      },
      {
        text: 'Lote',
        filtereable: false,
        sortable: true,
        value: 'lote',
        align: 'center',
        divider: true
      }
    ],
    ppr: 5
  }),
  props: {
    value: Boolean,
    provider: Object
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$refs.buyOrderForm.reset()
        this.buyOrder = {
          currency: 'SOLES'
        }

        this.$emit('input', value)
      }
    },
    current_ammount: {
      get() {
        let total_ammount = this.selectedProviderProducts.reduce(
          (total, current_product) => {
            return total + current_product.price * current_product.quantity
          },
          0
        )
        console.log(total_ammount)
        return total_ammount.toFixed(2)
      }
    }
  },
  watch: {
    show(value) {
      console.log('Opened', value)
      if (value) {
        this.provider_name = this.provider.name
        this.$makeGetRequest(`/api/products`).then(response => {
          console.log(response)
          this.providerProducts = response.data.data
        })
        return
      } else {
        this.selectedProviderProducts.splice(0)
        console.log(this.selectedProviderProducts)
        this.selectedProduct = {}
        this.providerProducts = []
      }
    },
    current_ammount(value) {
      console.log('watch ammount:', value)
      this.buyOrder.ammount_payed = parseFloat(value)
    }
  },
  methods: {
    addProduct: async function() {
      let product_in_array = await this.selectedProviderProducts.find(
        product => product.id === this.selectedProduct.id
      )
      if (product_in_array) {
        return
      }
      console.log()
      await this.selectedProviderProducts.push({
        id: this.selectedProduct.id,
        code: this.selectedProduct.code,
        description: this.selectedProduct.description,
        price: this.selectedProduct.price,
        lote: '',
        quantity: '1'
      })
      // await this.selectedProviderProducts.push(product_to_add)
      // console.log(this.selectedProviderProducts)
      this.selectedProduct = {}
      this.ppr = 10
    },
    storeBuyOrder: async function() {
      this.buyOrder.provider_id = this.provider.id
      // console.log(this.buyOrder)
      // console.log(this.selectedProviderProducts)
      let buyOrderData = {
        buyOrder: this.buyOrder,
        selectedProducts: this.selectedProviderProducts
      }
      console.log(buyOrderData)
      if (!this.$refs.buyOrderForm.validate()) {
        return
      }
      console.log('Store')
      try {
        let res = await this.$makePostRequest(
          `/api/providers/${this.provider.id}/storeBuyOrder`,
          buyOrderData
        )
        console.log(res)
        this.show = false
        this.$showMessage('Orden de compra creada exitosamente', 'success')
      } catch (err) {
        console.log(err.response.data)
        this.$showMessage('Hubo un error', 'error')
      }
    },
    filterObject(item, queryText) {
      return (
        item.description
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      )
    },
    saveLote() {},
    cancelLote() {}
  }
}
</script>
