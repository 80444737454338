<template>
  <div class="home">
    <create-provider v-model="crear" v-on:input="dialogClosed" />
    <provider-products
      v-model="productsDialog"
      v-bind:provider="selectedProvider"
    />
    <buy-order
      v-model="buyOrderDialog"
      v-bind:provider="selectedProvider"
      v-on:input="buyOrderClosed"
      :key="buyOrderKey"
    />
    <v-container class="my-6">
      <div>
        <v-row>
          <v-col cols="12" md="6">
            <div class="text-h5 text-md-h3">Lista de Proveedores</div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            align-self="center"
            cols="12"
            md="6"
            class="d-flex justify-md-end"
          >
            <v-btn
              outlined
              elevation="7"
              color="secondary"
              @click="crear = !crear"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="providers"
              item-key="id"
              class="elevation-1"
              :search="search"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ $formatDate(item.created_at) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon medium @click="nuevaCompra(item)" class="mr-2">
                  mdi-shopping
                </v-icon>
                <v-icon medium @click="verProductos(item)">
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import BuyOrder from '../components/providers/BuyOrder.vue'
import CreateProvider from '../components/providers/CreateProvider'
import ProviderProducts from '../components/providers/ProviderProducts'

export default {
  data: () => ({
    search: '',
    providers: [],
    crear: false,
    selectedProvider: {},
    productsDialog: false,
    buyOrderDialog: false,
    buyOrderKey: 0
  }),
  components: { CreateProvider, ProviderProducts, BuyOrder },
  computed: {
    headers() {
      return [
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'RUC',
          value: 'ruc'
        },
        { text: 'Teléfono', value: 'phone' },
        {
          text: 'Fecha de Creación',
          value: 'created_at'
        },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'center' }
      ]
    }
  },
  mounted: function() {
    this.$makeGetRequest('/api/providers').then(response => {
      this.providers = response.data.data
    })
  },
  methods: {
    async save() {
      this.product.class_id = this.items.indexOf(this.product.class) + 1
      console.log(this.product)
      await this.$makePostRequest('api/products/store', this.product)
      this.$makeGetRequest('/api/products').then(response => {
        console.log('Update data')
        this.products = response.data.data
        this.$showMessage('Producto insertado', 'success')
      })
    },
    async dialogClosed() {
      if (this.crear === false) {
        await this.$makeGetRequest('/api/providers').then(response => {
          this.providers = response.data.data
        })
      }
    },
    buyOrderClosed() {
      if (this.buyOrderDialog === false) {
        console.log('buy order dialog closed')
        // location.reload()
        this.buyOrderKey += 1
      }
    },
    verProductos(item) {
      console.log(item)
      this.selectedProvider = item
      this.productsDialog = true
    },
    nuevaCompra(item) {
      console.log(item)
      this.selectedProvider = item
      this.buyOrderDialog = true
    }
  }
}
</script>
