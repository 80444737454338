<template>
  <v-dialog v-model="show" max-width="50%">
    <v-card>
      <v-card-title>
        <span>Crear nuevo proveedor</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="providerForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="provider.name"
              :rules="providerRules.name"
              label="Nombre"
              required
            />

            <v-text-field
              v-model="provider.ruc"
              :rules="providerRules.ruc"
              counter="11"
              label="RUC"
              required
            />
            <v-text-field
              v-model="provider.phone"
              counter="9"
              :rules="providerRules.phone"
              label="Teléfono"
              required
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="show = false">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="storeProvider">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    provider: {},
    providerRules: {
      name: [v => !!v || 'Ingrese el nombre del proveedor'],
      phone: [
        v => !!v || 'Ingrese el teléfono del proveedor',
        v =>
          (v && v.length <= 9) ||
          'El teléfono debe ser menor o igual a 9 caracteres'
      ],
      ruc: [
        v => !!v || 'Ingrese el ruc del proveedor',
        v => (v && v.length === 11) || 'El RUC debe tener 11 caracteres'
      ]
    },
    valid: true
  }),
  props: {
    value: Boolean
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$refs.providerForm.reset()
        this.provider = {}

        this.$emit('input', value)
      }
    }
  },
  methods: {
    storeProvider: async function() {
      if (!this.$refs.providerForm.validate()) {
        return
      }
      console.log('Store')
      try {
        let res = await this.$makePostRequest(
          '/api/providers/store',
          this.provider
        )
        console.log(res)
        this.show = false
        this.$showMessage('Proveedor creado exitosamente', 'success')
      } catch (err) {
        console.log(err.response.data)
        this.$showMessage('Hubo un error', 'error')
      }
    }
  }
}
</script>
