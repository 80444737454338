<template>
  <v-dialog v-model="show" max-width="80%">
    <v-card>
      <v-card-title>
        <span>Productos ofrecidos por el proveedor: {{ provider.name }}</span>
        <v-spacer />
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="selectedProduct"
                :items="allProducts"
                label="Escriba el codigo de un producto"
                item-text="description"
                item-value="id"
                :filter="filterObject"
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.description"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.code"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="provider_data.desc"
                counter="30"
                label="Descripción del proveedor"
                required
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="provider_data.price"
                :prefix="'S/.'"
                label="Precio del proveedor"
                required
              />
            </v-col>
            <v-col cols="3" class="ml-auto">
              <v-btn elevation="1" color="secondary" @click="addProduct">
                Añadir producto
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col>
              <v-data-table
                :headers="headers"
                :items="products"
                item-key="id"
                class="elevation-3"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="blue darken-1" text @click="show = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    products: [],
    allProducts: [],
    selectedProduct: '',
    provider_data: {}
  }),
  props: {
    value: Boolean,
    provider: Object
  },
  watch: {
    show(value) {
      console.log('Opened', value)
      if (!value) return
      this.$makeGetRequest(`/api/providers/${this.provider.id}/products`).then(
        response => {
          // console.log(response)
          this.products = response.data.data
        }
      )

      this.$makeGetRequest('/api/products').then(response => {
        this.allProducts = response.data.data
      })
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.products = []
        this.provider_data = {}
        this.$emit('input', value)
      }
    },
    headers() {
      return [
        {
          text: 'Código',
          align: 'start',
          sortable: true,
          value: 'code'
        },
        {
          text: 'Descripción',
          value: 'description',
          filtereable: true
        },
        { text: 'Clase', value: 'class' },
        { text: 'Precio', value: 'price' }
      ]
    }
  },
  methods: {
    addProduct: async function() {
      console.log(this.selectedProduct)
      await this.$makePostRequest(
        `/api/providers/${this.provider.id}/addProduct`,
        { product_id: this.selectedProduct, provider_data: this.provider_data }
      )
      this.$makeGetRequest(`/api/providers/${this.provider.id}/products`).then(
        response => {
          // console.log(response)
          this.products = response.data.data
        }
      )
      this.provider_data = {}

      this.selectedProduct = ''
    },
    filterObject(item, queryText) {
      return (
        item.description
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      )
    }
  }
}
</script>
